<template>
    <div class="k-card-1">
        <h1 class="k-flex-row">
            <b-badge variant="kalkin-2"> {{ installation.type }}</b-badge>
            {{ installation.name }}
            <div class="k-flex-line" />
            <b-button
                v-if="selected"
                size="xs"
                variant="kalkin-1"
                @click="$emit('deselect')"
            >
                <b-icon-geo-alt-fill /> Sélectionné
            </b-button>
            <b-button
                v-else
                size="xs"
                variant="outline-kalkin-1"
                @click="$emit('select')"
            >
                <b-icon-geo-alt-fill /> Voir sur la carte
            </b-button>
        </h1>

        <div class="install-grid">
            <div>
                <small class="text-kalkin-2">Emplacement</small>
                <div>{{ installation.place || "Non défini" }}</div>
            </div>

            <div class="k-flex-row-2 k-gap-l align-items-start">
                <div>
                    <small class="text-kalkin-2">Longitude</small>
                    <div>{{ installation.longitude }}</div>
                </div>
                <div>
                    <small class="text-kalkin-2">Latitude</small>
                    <div>{{ installation.latitude }}</div>
                </div>
            </div>

            <div>
                <small class="text-kalkin-2">État de la connexion</small>
                <div v-if="teamviewer" class="k-flex-row-2 flex-nowrap">
                    <template v-if="teamviewer.online">
                        <b-icon-record-circle-fill
                            font-scale="1.2"
                            variant="kalkin-2"
                        />
                        <small>Connecté</small>
                    </template>
                    <template v-else>
                        <b-icon-slash-circle-fill
                            font-scale="1.2"
                            variant="danger"
                        />
                        <small>
                            Déconnecté depuis
                            <template v-if="teamviewer.lastSeen">
                                le {{ teamviewer.lastSeen | dateFromISO }}
                            </template>
                            <template v-else> une date inconnue</template>
                        </small>
                    </template>
                </div>
                <div class="text-secondary" v-else>
                    Connexion non configurée
                </div>
            </div>

            <div>
                <small class="text-kalkin-2">État de l'application</small>
                <div class="k-flex-row-2 flex-nowrap">
                    <template v-if="durationSinceContact <= 0">
                        <b-icon-check-circle-fill
                            font-scale="1.2"
                            variant="kalkin-2"
                        />
                    </template>
                    <template v-else-if="durationSinceContact <= 1">
                        <b-icon-exclamation-circle-fill
                            font-scale="1.2"
                            variant="warning"
                        />
                    </template>
                    <template v-else>
                        <b-icon-exclamation-triangle-fill
                            font-scale="1.2"
                            variant="danger"
                        />
                    </template>

                    <small v-if="installation.contactedAt">
                        Données mises à jour le
                        {{ installation.contactedAt | dateFromISO }}
                    </small>
                    <small v-else> Données jamais mises à jour </small>
                </div>
                <div
                    class="k-flex-col k-gap-none"
                    v-if="iframe && iframe.position == 'app'"
                >
                    <div class="k-flex-row k-flex-fill mt-2">
                        <b-button
                            v-show="!showIframe"
                            @click="showIframe = true"
                            variant="outline-kalkin-2"
                            size="xs"
                        >
                            <b-icon-caret-down-fill />
                            Voir détails
                        </b-button>
                        <b-button
                            v-show="showIframe"
                            @click="showIframe = false"
                            variant="outline-kalkin-2"
                            size="xs"
                        >
                            <b-icon-caret-up-fill />
                            Masquer détails
                        </b-button>
                    </div>
                    <b-collapse id="iframe-app" :visible="showIframe">
                        <iframe
                            :src="iframe.url"
                            width="100%"
                            :height="iframe.height || '250px'"
                            frameborder="0"
                        />
                    </b-collapse>
                </div>
            </div>
            <div v-if="visible">
                <small class="text-kalkin-2">Modifier</small>
                <div class="k-flex-row k-flex">
                    <b-button
                        class="m-2"
                        size="xs"
                        variant="outline-secondary"
                        @click="$emit('contactUpdate')"
                    >
                        <b-icon-pencil />
                    </b-button>
                </div>
            </div>
            <div v-if="iframe && iframe.position == 'grid'">
                <iframe
                    :src="iframe.url"
                    width="100%"
                    :height="iframe.height || '250px'"
                    frameborder="0"
                />
            </div>
        </div>
        <div v-if="iframe && iframe.position == 'line'">
            <iframe
                :src="iframe.url"
                width="100%"
                :height="iframe.height || '250px'"
                frameborder="0"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        installation: { type: Object, required: true },
        selected: { type: Boolean, default: false },
    },

    data: function() {
        let iframe = this.iframe;
        return {
            projectId: this.$route.params.projectId,
            showIframe: iframe ? !iframe.hidden : true,
            visible: false,
        };
    },

    methods: {
        async checkUserPermissions() {
            this.visible = await this.$store.dispatch(
                "user/getMyProjectCreds",
                {
                    projectId: this.projectId,
                },
            );
        },
    },

    beforeMount() {
        this.checkUserPermissions();
    },

    computed: {
        teamviewer() {
            return this.installation.teamviewer;
        },

        iframe() {
            if (this.installation && this.installation.config) {
                let config = JSON.parse(this.installation.config);
                if (config.iframe) {
                    config.iframe.position = config.iframe.position || "app";
                    return config.iframe;
                }
            }
            return null;
        },

        durationSinceContact() {
            const contactedAt = this.installation.contactedAt;
            if (contactedAt) {
                const d = new Date(contactedAt).setHours(0, 0, 0, 0);
                const today = new Date().setHours(0, 0, 0, 0);
                return Math.round(Math.abs((today - d) / 86400000));
            } else {
                return Number.MAX_SAFE_INTEGER;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.install-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    column-gap: 10px;
}
#iframe-app {
    padding: 0 5px;
    & iframe {
        border: 1px solid $secondary-color;
        border-top: none;
        background: white;
        border-radius: 0 0 6px 6px;
    }
}
</style>
