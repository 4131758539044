<template>
    <b-overlay
        class="k-page-flex"
        variant="white"
        spinner-variant="kalkin-2"
        :show="isLoading"
        z-index="10000"
    >
        <div class="k-page-title">
            <h1>Mes installations</h1>
        </div>
        <div class="map-container">
            <MapPOIViewer
                :pois="pois"
                :selected="selected"
                @poi-clicked="selected = $event"
            />
        </div>

        <div class="k-flex-col" v-if="installations.length">
            <CardMemberInstallation
                v-for="installation in installations"
                :installation="installation"
                :key="installation.key"
                :project-id="projectId"
                :selected="selected === installation.id"
                @select="selected = installation.id"
                @deselect="selected = undefined"
                @contactUpdate="onInstallationContactUpdate(installation)"
            />
        </div>
        <div class="mt-5 text-center" v-else>
            <em>Aucune installation associée à la configuration</em>
        </div>
        <ModalContactUpdateInstallation
            :project-id="projectId"
            :installation="selectedInstallation"
            @saved="onInstallationUpdated"
        />
    </b-overlay>
</template>

<script>
import MapPOIViewer from "@/components/map/MapPOIViewer";
import CardMemberInstallation from "@/components/model/installation/CardMemberInstallation";
import ModalContactUpdateInstallation from "@/components/model/installation/ModalContactUpdateInstallation";

export default {
    components: {
        MapPOIViewer,
        ModalContactUpdateInstallation,
        CardMemberInstallation,
    },

    data: function() {
        return {
            projectId: this.$route.params.projectId,
            installations: [],
            selectedInstallation: {},

            isLoading: false,
            selected: null,
        };
    },

    computed: {
        pois() {
            return this.installations.map(install => ({
                id: install.id,
                lat: install.latitude,
                lng: install.longitude,
                popup: install.name,
            }));
        },
    },

    methods: {
        onInstallationContactUpdate(installation) {
            this.selectedInstallation = installation;
            this.$bvModal.show("ModalContactUpdateInstallation");
        },

        async onInstallationUpdated() {
            this.selectedInstallation = {};
            await this.fetchInstallations();
        },

        async fetchInstallations() {
            this.isLoading = true;
            const projectId = this.$route.params.projectId;
            const configId = this.$route.params.configId;
            this.installations = await this.$store.dispatch(
                "application/getInstallations",
                { projectId, configId, teamviewer: true },
            );
            this.isLoading = false;
        },
    },

    async beforeMount() {
        await this.fetchInstallations();
    },
};
</script>

<style lang="scss" scoped>
.map-container {
    display: flex;
    justify-content: center;
    min-height: max(200px, 33%);

    & > :first-child {
        max-width: max(350px, 60%);
    }
}
</style>
