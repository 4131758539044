<template>
    <div class="k-panel-1">
        <div class="k-flex-row k-flex-fill align-items-start">
            <div>
                <label> <b>Nom</b> <span class="text-danger">*</span></label>
                <b-form-input
                    :disabled="true"
                    v-model="localInstallation.name"
                    placeholder="Nom de l'installation"
                    :state="isNameValid"
                    @input="hasChanged = true"
                    trim
                />
            </div>
            <div>
                <label> <b>Type</b> <span class="text-danger">*</span></label>
                <b-form-select
                    :disabled="true"
                    v-model="localInstallation.type"
                    :options="installationTypeOptions"
                    :state="isTypeValid"
                    @input="hasChanged = true"
                >
                    <template #first>
                        <b-form-select-option :value="undefined" disabled>
                            -- Sélectionner le type de l'installation --
                        </b-form-select-option>
                    </template>
                </b-form-select>
                <b-form-invalid-feedback :state="isTypeValid">
                    Veuillez choisir un type
                </b-form-invalid-feedback>
            </div>
        </div>

        <div>
            <label>
                <b>Géolocalisation</b> <span class="text-danger">*</span>
            </label>
            <MapPointSelector
                :longitude="localInstallation.longitude"
                :latitude="localInstallation.latitude"
                :state="isGeoValid"
                @input="updateGeo($event)"
            />
        </div>

        <div>
            <label>Lieu de l'installation</label>
            <b-form-input
                v-model="localInstallation.place"
                placeholder="Adresse, emplacement, etc"
                @input="hasChanged = true"
                trim
            />
        </div>

        <div class="text-center">
            <b-button
                variant="kalkin-2"
                :disabled="!hasChanged"
                @click="saveInstallation()"
            >
                <b-icon-check-circle-fill /> Enregistrer les modifications
            </b-button>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import MapPointSelector from "@/components/map/MapPointSelector";
import configs from "@/config/installation";

export default {
    components: {
        MapPointSelector,
    },

    props: {
        projectId: { type: [String, Number], required: true },
        installation: { type: Object, required: false },
    },

    data: function() {
        let localInstallation = Vue.util.extend({}, this.installation);
        return {
            localInstallation,
            isEditMode: this.installation != null,
            installationTypeOptions: Object.keys(configs).map(t => {
                return { text: t, value: t };
            }),

            hasChanged: false,
            isConfigValid: true,
        };
    },

    computed: {
        isNameValid() {
            if (this.localInstallation.name === undefined) {
                return null;
            } else {
                return !!this.localInstallation.name;
            }
        },

        isTypeValid() {
            if (this.localInstallation.type === undefined) {
                return null;
            } else {
                return this.localInstallation.type !== null;
            }
        },

        isGeoValid() {
            if (
                this.localInstallation.longitude === undefined ||
                this.localInstallation.latitude === undefined
            ) {
                return null;
            } else {
                return (
                    !!this.localInstallation.longitude ||
                    !!this.localInstallation.latitude
                );
            }
        },

        isValid() {
            return (
                this.isNameValid &&
                this.isTypeValid &&
                this.isGeoValid &&
                this.isConfigValid
            );
        },
    },

    methods: {
        updateGeo({ longitude, latitude }) {
            this.localInstallation.longitude = longitude;
            this.localInstallation.latitude = latitude;
            this.hasChanged = true;
        },

        async saveInstallation() {
            if (this.isValid) {
                await this.$store.dispatch("project/updateInstallation", {
                    projectId: this.projectId,
                    installationId: this.installation.id,
                    ...this.localInstallation,
                });
                this.$emit("saved");
                this.hasChanged = false;
            } else {
                this.localInstallation.name = this.localInstallation.name || "";
                this.localInstallation.type =
                    this.localInstallation.type || null;
                this.localInstallation.longitude =
                    this.localInstallation.longitude || "";
                this.localInstallation.latitude =
                    this.localInstallation.latitude || "";
            }
        },
    },
};
</script>
