<template>
    <div id="main">
        <div id="map-container">
            <MapViewer
                class="map-content"
                v-bind="$props"
                @map-ready="onMapReady"
            />
            <div
                id="map-overlay"
                class="map-content"
                @click="displayOverlay = false"
                v-show="displayOverlay"
            >
                <span>Cliquez ici pour placer un point</span>
            </div>
        </div>
        <div id="form">
            <b-form-group>
                <label>Longitude</label>
                <b-form-input
                    v-model="localLongitude"
                    step="0.00001"
                    min="-180"
                    max="180"
                    type="number"
                    size="sm"
                    :state="state"
                    :disabled="!markerLayer"
                    @change="onCoordsChange"
                />
                <b-form-invalid-feedback :state="state">
                    Veuillez placer un point sur la carte
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
                <label>Latitude</label>
                <b-form-input
                    v-model="localLatitude"
                    step="0.00001"
                    min="-90"
                    max="90"
                    type="number"
                    size="sm"
                    :state="state"
                    :disabled="!markerLayer"
                    @change="onCoordsChange"
                />
                <b-form-invalid-feedback :state="state">
                    Veuillez placer un point sur la carte
                </b-form-invalid-feedback>
            </b-form-group>
        </div>
    </div>
</template>
<script>
import { LExtended as L } from "geoportal-extensions-leaflet";
import MapViewer from "./MapViewer";

const basePin = L.divIcon({
    className: "k-map-pin-base",
    iconAnchor: [20, 40],
    iconSize: [40, 40],
    popupAnchor: [0, -40],
    html: "<div/>",
});

const markerStyle = { icon: basePin };

export default {
    components: {
        MapViewer,
    },

    props: {
        center: Array,
        zoom: Number,
        state: { type: [Boolean, null], default: null },
        longitude: String,
        latitude: String,
    },

    data: function() {
        if (this.longitude && this.latitude) {
            return {
                map: null,
                displayOverlay: false,
                markerLayer: this.createMarker(this.longitude, this.latitude),
                localLongitude: this.longitude,
                localLatitude: this.latitude,
            };
        } else {
            return {
                map: null,
                displayOverlay: true,
                markerLayer: null,
                localLongitude: "",
                localLatitude: "",
            };
        }
    },

    watch: {
        longitude() {
            if (this.longitude) {
                if (this.longitude !== this.localLongitude) {
                    this.localLongitude = this.longitude;
                    if (this.localLatitude) {
                        this.displayOverlay = false;
                        this.markerLayer = this.createMarker(
                            this.localLongitude,
                            this.localLatitude,
                        );
                    }
                }
            } else {
                this.displayOverlay = true;
                this.localLongitude = "";
                this.initNewMarker();
            }
        },

        latitude() {
            if (this.latitude) {
                if (this.latitude !== this.localLatitude) {
                    this.localLatitude = this.latitude;
                    if (this.localLongitude) {
                        this.displayOverlay = false;
                        this.markerLayer = this.createMarker(
                            this.localLongitude,
                            this.localLatitude,
                        );
                    }
                }
            } else {
                this.displayOverlay = true;
                this.localLatitude = "";
                this.initNewMarker();
            }
        },
    },

    methods: {
        createMarker(long, lat) {
            let layer = L.marker([Number(lat), Number(long)], markerStyle);
            if (this.map) {
                if (this.markerLayer) {
                    this.map.removeLayer(this.markerLayer);
                }
                layer.addTo(this.map);
                layer.on("pm:dragend", this.onMarkerChange);
                layer.pm.enable();
            }
            return layer;
        },

        initNewMarker() {
            if (this.map) {
                if (this.markerLayer) {
                    this.map.removeLayer(this.markerLayer);
                    this.markerLayer = null;
                }
                this.map.pm.enableDraw("Marker", { markerStyle });
            }
        },

        onMapReady: function(map) {
            this.map = map;
            map.pm.setLang("fr");

            map.on("pm:create", ({ layer }) => {
                this.markerLayer = layer;
                this.map.pm.disableDraw();
                this.markerLayer.on("pm:dragend", this.onMarkerChange);
                this.markerLayer.pm.enable();
                this.onMarkerChange();
            });

            if (this.markerLayer) {
                this.markerLayer.addTo(map);
                this.markerLayer.on("pm:dragend", this.onMarkerChange);
                this.markerLayer.pm.enable();
                this.map.flyTo(this.markerLayer.getLatLng(), 10);
            } else {
                this.initNewMarker();
            }
        },

        onMarkerChange: function() {
            const coords = this.markerLayer.getLatLng();
            this.localLongitude = coords.lng.toFixed(5);
            this.localLatitude = coords.lat.toFixed(5);
            this.onChange();
        },

        onCoordsChange: function() {
            this.markerLayer.setLatLng({
                lat: Number(this.localLatitude),
                lng: Number(this.localLongitude),
            });
            this.markerLayer.pm.enable();
            this.onChange();
        },

        onChange: function() {
            this.$emit("input", {
                longitude: this.localLongitude,
                latitude: this.localLatitude,
            });
        },
    },
};
</script>
<style lang="scss" scoped>
#main {
    display: flex;
    justify-content: center;
    align-items: stretch;

    flex-wrap: wrap;
    width: 100%;
}
#map-container {
    flex-grow: 2;
    flex-basis: 400px;
    min-height: 300px;
    height: auto;
    position: relative;
}
.map-content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(100% - 20px) !important;
    margin: 0 10px;
}
#map-overlay {
    z-index: 9999;
    background: rgba(0, 0, 0, 0.6);
    background: radial-gradient(
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 0.7) 50%,
        rgba(255, 255, 255, 0.9) 100%
    );
    padding: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
#map-overlay:hover {
    cursor: pointer;
}
#form {
    flex-grow: 1;
    flex-basis: 200px;

    padding: 5px 15px;
}
</style>
