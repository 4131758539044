const context = require.context(
    "@/assets/json/installation/",
    false,
    /^.*\.json$/,
);

module.exports = context.keys().reduce((acc, file) => {
    const config = context(file);
    acc[config.type] = config;
    return acc;
}, {});
